interface Toast {
  id: number
  message: string
  duration: number
  type: string
}

interface ToastMessage {
  toasts: Ref<Toast[]>
  logger: (message: string, e?: unknown) => void
  showError: (message: string, e?: unknown) => void
  showSuccess: (message: string, e?: unknown) => void
  showWarning: (message: string, e?: unknown) => void
  showInfo: (message: string, e?: unknown) => void
}

function getErrorMessage(error: unknown) {
	if (error instanceof Error) return error.message
	return String(error)
}

export const useToast = (): ToastMessage => {
  const { t } = useI18n()
  const toasts = useState<Toast[]>('toasts', () => [])

  const showToast = (message: string, { duration = 5000, type = 'info' } = {}) => {
    const toast = { id: Date.now(), message, duration, type }
    toasts.value.push(toast)
    setTimeout(() => {
      toasts.value = toasts.value.filter(t => t.id !== toast.id)
    }, duration)
  }

  /*
   * logger sends a message via logtail but does not show it in the UI
  */
  const logger = (message: string, e?: unknown) => {
    console.info(getErrorMessage(e))
  }

  const showError = (message: string, e?: unknown) => {
    console.error(getErrorMessage(e))
    showToast(message || t('messages.error'), {
      type: 'error'
    })
  }

  const showSuccess = (message: string, e?: unknown) => {
    console.debug(getErrorMessage(e))
    showToast(message || t('messages.success'), {
      type: 'success'
    })
  }

  const showWarning = (message: string, e?: unknown) => {
    console.debug(getErrorMessage(e))
    showToast(message || t('messages.warning'), {
      type: 'warning'
    })
  }

  const showInfo = (message: string, e?: unknown) => {
    console.info(getErrorMessage(e))
    showToast(message || t('messages.info'), {
      type: 'info'
    })
  }

  return {
    toasts,
    showError,
    showSuccess,
    showWarning,
    showInfo,
    logger
  }
}
