import revive_payload_client_eBjNrKzIIH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_eslint@9.16.0_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QB3CNdEyEm from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_eslint@9.16.0_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MPFOxFBGDB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_eslint@9.16.0_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_lekvWb1bWf from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.4/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_KeuyVhEz1s from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.28.1_vite@5.4.11_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_UyTwM10ezq from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_eslint@9.16.0_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hmry92i2ew from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_eslint@9.16.0_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_l3XBjVp2ve from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_eslint@9.16.0_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_k6ffDfPltg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@types+node@22.10.2_eslint@9.16.0_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@5.4.11_vue-tsc@2.1.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_SU0Cnb0nNX from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_rollup@4.28.1_unhead@1.11.14_vite@5.4.11_vue@3.5.13/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_mgUC0E5dOA from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_rollup@4.28.1_unhead@1.11.14_vite@5.4.11_vue@3.5.13/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_78yCleCWOv from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.28.1_vite@5.4.11_vue@3.5.13/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_JxaOq7Vcid from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_rollup@4.28.1_vite@5.4.11_vue@3.5.13/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_VFGXwP2vTv from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.28.1_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ISLZf8c1Lk from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.28.1_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import umami_iN1DWXMIUx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-umami@2.6.7/node_modules/nuxt-umami/plugins/umami.ts";
import scrollTo_IIJedyuVwF from "/opt/buildhome/repo/plugins/scrollTo.ts";
import stripe_6FZUdDHpIZ from "/opt/buildhome/repo/plugins/stripe.ts";
import sentry_3AyO8nEfhE from "/opt/buildhome/repo/plugins/sentry.ts";
export default [
  revive_payload_client_eBjNrKzIIH,
  unhead_QB3CNdEyEm,
  router_MPFOxFBGDB,
  supabase_client_lekvWb1bWf,
  _0_siteConfig_KeuyVhEz1s,
  navigation_repaint_client_UyTwM10ezq,
  check_outdated_build_client_hmry92i2ew,
  chunk_reload_client_l3XBjVp2ve,
  components_plugin_KR1HBZs4kY,
  prefetch_client_k6ffDfPltg,
  titles_SU0Cnb0nNX,
  defaultsWaitI18n_mgUC0E5dOA,
  siteConfig_78yCleCWOv,
  inferSeoMetaPlugin_JxaOq7Vcid,
  switch_locale_path_ssr_VFGXwP2vTv,
  i18n_ISLZf8c1Lk,
  umami_iN1DWXMIUx,
  scrollTo_IIJedyuVwF,
  stripe_6FZUdDHpIZ,
  sentry_3AyO8nEfhE
]