<script setup lang="ts">
const props = defineProps<{
  version: 'success' | 'error' | 'warning' | 'info',
}>()
</script>

<template>
  <div :class="`message ${props.version}`">
    <slot/>
  </div>
</template>

<style scoped lang="postcss">
.message {
  padding: 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.success {
  background-color: var(--color-emerald-400);
  border: 1px solid var(--color-emerald-500);
  color: var(--color-emerald-100);
}

.error {
  background-color: var(--color-rose-400);
  border: 1px solid var(--color-rose-500);
  color: var(--color-rose-100);
}

.info {
  background-color: var(--color-blue-400);
  border: 1px solid var(--color-blue-500);
  color: var(--color-blue-100);
}

.warning {
  background-color: var(--color-orange-100);
  border: 1px solid var(--color-orange-400);
  color: var(--color-orange-700);
}
</style>
