<script lang="ts" setup>
const { signOut, currentUser, fetchCurrentUser } = useAuth()
const { pending } = await useAsyncData('user', fetchCurrentUser)


interface MenuItem {
  label: string;
  link?: string;
  items?: Array<MenuItem>;
  style?: string;
  icon?: string;
  action?: () => void;
}

const mainMenu:Array<MenuItem> = [
  {
    label: 'Explore',
    link: '/explore'
  },
  {
    label: 'Maps',
    link: '/maps'
  },
  {
    label: 'Destinations',
    link: '/destinations'
  },
  {
    label: 'Creators',
    link: '/creators',
    // items: [
    //   {
    //     label: 'For Creators',
    //     link: '/creators'
    //   },
    //   {
    //     label: 'Creator Studio',
    //     link: '/creators'
    //   },
    //   {
    //     label: 'Memberships',
    //     link: '/creators'
    //   },
    //   {
    //     label: 'Guides',
    //     link: '/creators'
    //   },
    //   {
    //     label: 'Revenue share',
    //     link: '/creators'
    //   },
    //   {
    //     label: 'Link in Bio',
    //     link: '/creators'
    //   },
    // ]
  },
  // {
  //   label: 'Resources',
  //   link: '/resources'
  // }
]

const userMenu:Array<MenuItem> = computed(() => {
  if (!currentUser.value) {
    return [
      {
        label: 'Login',
        link: '/auth',
        style: 'link'
      },
      {
        label: 'Join',
        link: '/auth',
        style: 'button'
      }
    ]
  } else {
    return [
      {
        label: 'Atals',
        link: '/atlas',
        icon: 'ri-compass-3-line'
      },
      {
        label: 'Account',
        icon: 'ri-account-circle-line',
        items: (!currentUser.value.profile.is_creator) ? [
          { label: 'Account', icon: 'ri-account-circle-line', link: '/account' },
          { label: 'Sign Out', icon: 'ri-logout-circle-r-line', action: () => signOut()},
        ] : [
          { label: 'Account', icon: 'ri-account-circle-line', link: '/account' },
          { label: 'Creator Studio', icon: 'ri-megaphone-line', link: '/studio/maps' },
          { label: 'Sign Out', icon: 'ri-logout-circle-r-line', action: () => signOut()},
        ]
      }
    ]
  }
})

const route = useRoute()
const isStudioPage = computed(() => {
  const pathSegments = route.path.split('/')
  return pathSegments[1] === 'studio'
})
const isMapPage = computed(() => {
  const pathSegments = route.path.split('/')
  return pathSegments[1] === 'maps' && pathSegments[2] !== undefined
})

const showMobileMenu = ref(false)
</script>

<template>
  <header v-if="!pending" role="banner" :class="[{ 'studio': isStudioPage, 'map-page': isMapPage }]">
    <NuxtLinkLocale :to="'/'" aria-role="link" aria-label="home page" class="logo">
      <MPLogo logo-type="full" height="2.5rem" />
    </NuxtLinkLocale>
    <NuxtLinkLocale v-if="isStudioPage" :to="'/'">
      <span class="studio-back-to-mappr">
        <i class="ri-arrow-drop-left-line" />&nbsp; <small>Back to Mappr</small>
      </span>
    </NuxtLinkLocale>
    <a class="nav-trigger" @click="showMobileMenu = true">
      <i class="ri-menu-3-line"/>
    </a>
    <nav :class="showMobileMenu ? 'is-open' : ''">
      <a class="nav-trigger" @click="showMobileMenu = false">
        <i class="ri-close-line"/>
      </a>
      <ul v-if="!isStudioPage" aria-label="Main menu" clas="main-menu">
        <li v-for="item in mainMenu" :key="item.label">
          <NuxtLinkLocale v-if="!item.items" :to="item.link" class="header-link" @click="showMobileMenu = false">
            <span>
              {{item.label}}
            </span>
          </NuxtLinkLocale>
          <MPMenu v-else :items="item.items">
            <span class="header-link">
              {{item.label}}
            </span>
          </MPMenu>
        </li>
      </ul>
      <ul aria-label="User menu" class="user-menu">
        <MPSearchInline  class="search"/>
        <li v-for="item in userMenu" :key="item.label">
          <NuxtLinkLocale v-if="!item.items" :to="item.link" @click="showMobileMenu = false">
            <MPButton v-if="item.icon" version="secondary" background-color="var(--color-white)" :icon="item.icon" rounded :link="item.style === 'link'" />
            <MPButton v-if="!item.icon" :label="item.label" rounded :link="item.style === 'link'" />
          </NuxtLinkLocale>
          <MPMenu
            v-if="item.items"
            :items="item.items"
          >
            <MPButton :icon="item.icon" version="secondary" background-color="var(--color-white)" rounded :link="item.style === 'link'" />
          </MPMenu>
        </li>
      </ul>
    </nav>
  </header>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/breakpoints.pcss";
@import "@/assets/css/typography.pcss";

header {
  position: relative;
  z-index: 1000;
  width: 100%;
  max-width: 82rem;
  margin: 0 auto;
  display: grid;
  justify-items: start;
  grid-template-columns: 10rem 1fr;
  padding: 1rem 1rem;
  font-family: 'Hanken Grotesk' !important;
  @mixin screen md {
    padding: 1rem 2rem;
  }
}

.search {
  display: none;
  @mixin screen md {
    display: flex;
  }
}

.logo {
  width: fit-content;
  margin-right: 1rem;
  z-index: 90;
}

.nav-trigger {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  @mixin screen md {
    display: none;
  }
}

nav {
  display: none;
  @mixin screen md {
    display: flex;
    all: unset;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    @mixin screen md {
      align-items: left;
      text-align: center;
    }
  }
  & .user-menu {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    text-align: left;
    align-items: center;
    button {
      box-shadow: var(--shaodw-sm);
      border: 1px  solid var(--color-slate-100);
      border-radius: 100%;
    }
    & li a {
      display: flex;
    }
  }
}

.header-link {
  color: var(--color-slate-500);
  padding: 0.65rem 0.75rem;
  display: block;
}

.header-link:hover,
.header-link.router-link-active {
  color: var(--color-slate-700);
  transition: text-decoration 0.25s ease-in;
}

nav.is-open {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-white);
  justify-content: space-between;
  flex-direction: column;
  z-index: 80;
  padding: 6rem 1rem 2rem;
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    align-items: left;
    text-align: left;
    @mixin typeface-h1;
  }
  & .user-menu {
    flex-direction: row;
  }
}

header.studio {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  width: 100%;
}

header.map-page {
  display: none;
  @mixin screen md {
    display: flex;
  }
  .header-link {
    color: var(--color-slate-50);
    padding: 0.65rem 0.75rem;
    display: block;
  }
}

.studio-back-to-mappr {
  display: flex;
  align-items: center;
  color: var(--color-slate-500);
}

.studio-back-to-mappr:hover {
  color: var(--color-slate-800);
}
</style>

<style>
header.map-page {
  & path {
    fill: var(--color-indigo-50);
  }
  & label {
    color: var(--color-indigo-50);
  }
  .mp-button {
    box-shadow: none;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
    & i {
      color: white;
    }
  }
}
</style>
