import type { Tables } from '@/types/database.types.ts'
import type { AuthUser } from '@supabase/supabase-js'

interface User {
  profile: Tables<'profiles'> | null;
  user: AuthUser | null;
  subscriptions: Tables<'platform_subscriptions'> | null;
  supports: Tables<'creator_supporters'>[] | null;
  bookmarks: {
    maps: Tables<'map_bookmarks'>[];
    spots: Tables<'spot_bookmarks'>[];
  } | null;
}

export const useAuth = () => {
  const supabase = useSupabaseClient()
  const router = useRouter()
  const { showError } = useToast()

  const currentUser = useState<User | null>('currentUser', () => null)

  supabase.auth.onAuthStateChange((session) => {
    if (session === 'SIGNED_OUT') {
      resetUser()
    } else if (session === 'PASSWORD_RECOVERY') {
      router.push('/app/reset-password')
    }
  })

  const fetchSubscriptions = async () => {
    if (!currentUser.value) return
    const { data, error } = await supabase
      .from('platform_subscriptions')
      .select('*')
      .eq('user_id', currentUser.value.user.id)
      .eq('status', 'active')
      .order('created_at', { ascending: false })
    if (error) showError('Could not fetch subscriptions')
    currentUser.value = {
      ...currentUser.value,
      subscriptions: data as Tables<'platform_subscriptions'>[]
    }
  }

  const fetchSupports = async () => {
    if (!currentUser.value) return
    const { data, error } = await supabase
      .from('creator_supporters')
      .select('*')
      .eq('user_id', currentUser.value.user.id)
      .eq('status', 'active')
      .order('created_at', { ascending: false })
    if (error) showError('Could not fetch supports')
    currentUser.value = {
      ...currentUser.value,
      supports: data as Tables<'creator_supporters'>[]
    }
  }


  const fetchMapBookmarks = async () => {
    if (!currentUser.value) return
    const { data , error } = await supabase
      .from('map_bookmarks')
      .select('*')
      .eq('user_id', currentUser.value.user.id)
      .order('created_at', { ascending: false })
    if (error) showError('Could not fetch map bookmarks')
    currentUser.value = {
        ...currentUser.value,
        bookmarks: {
          ...currentUser.value.bookmarks,
          maps: data as Tables<'map_bookmarks'>[]
        }
    }
    return data
  }

  const fetchSpotBookmarks = async () => {
    if (!currentUser.value) return
    const { data, error } = await supabase
      .from('spot_bookmarks')
      .select('*')
      .eq('user_id', currentUser.value?.user.id)
      .order('created_at', { ascending: false })
    if (error) showError('Could not fetch spot bookmarks')
    currentUser.value = {
      ...currentUser.value,
      bookmarks: {
        ...currentUser.value.bookmarks,
        spots: data as Tables<'spot_bookmarks'>[]
      }
    }
    return data
  }

  const fetchCurrentUser = async () => {
    const { data: { user }, error } = await supabase.auth.getUser()
    if (error || !user) {
      currentUser.value = null
    } else {
      const { data: profileData } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single()
      if (error) showError('Could not fetch profile information')
      currentUser.value = {
        profile: profileData,
        user
      }
      await fetchMapBookmarks()
      await fetchSpotBookmarks()
      await fetchSubscriptions()
      await fetchSupports()
    }
    return currentUser
  }

  const resetUser = () => {
    currentUser.value = null
  }

  const signInWithEmail = async (email: string, password: string) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password
    })
    if (error) throw error
      else await fetchCurrentUser()
    return data
  }

  const signOut = async () => {
    const { error } = await supabase.auth.signOut()
    if (error) showError('Error whilst signing out')
    resetUser()
    router.push('/')
    reloadNuxtApp()
  }

  return {
    fetchCurrentUser,
    fetchMapBookmarks,
    fetchSpotBookmarks,
    currentUser,
    signOut,
    signInWithEmail
  }
}
